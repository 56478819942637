<template>
  <div style=" width: 100%; height: 100%; padding: 0 15px 15px;">
    <b-section-scroll class="g-bg">
      <!-- 头部 -->
      <div
        slot="header"
        class="header"
      >
        <el-tabs
          class="r-nav"
          v-model="activeName"
        >
          <el-tab-pane
            label="部门任务"
            name="dept"
          ></el-tab-pane>
          <el-tab-pane
            label="个人任务"
            name="user"
          ></el-tab-pane>
        </el-tabs>
        <div>
          <span>状态：</span>
          <!-- 进行中，已完成，超时完成,超时未完成 -->
          <el-select
            v-model="state"
            style="width: 200px;"
          >
            <el-option
              label="进行中"
              value="进行中"
            ></el-option>
            <el-option
              label="已逾期"
              value="超时未完成"
            ></el-option>
            <el-option
              label="已完成"
              value="已完成,超时完成"
            ></el-option>
            <el-option
              label="超时完成"
              value="超时完成"
            ></el-option>
          </el-select>
        </div>

      </div>
      <!-- 内容 -->
      <div v-loading="loading">
        <div
          v-if="!list || list.length === 0"
          class="g-empty"
        ></div>

        <el-row :gutter="10">
          <el-col
            v-for="item in list"
            :key="item.id"
            :span="6"
            class="g-mb-10"
          >
            <div
              class="list-item"
              :class="{warning: handlerStatus(item)}"
              @click="onNav(item)"
            >
              <div class="title g-text-ellipsis2">{{ item.name }}</div>
              <div class="time">
                <i class="el-icon-user"></i>
                <span>创建人：</span>
                <span v-if="item.user&&item.user.department">{{item.user.department.name}}</span>
                <span>({{ item.user&&item.user.name }})</span>
              </div>
              <div class="user">
                <i class="el-icon-time"></i>
                <span>截止时间：</span>
                <span>{{ item.executive_end_data&&item.executive_end_data.split(' ')[0] }}</span>
              </div>
              <div
                class="state"
                :style="{'background-color': handlerColor(item.state)}"
              >{{item.forward_state === 1 ? '转 ' : ''  }}{{ item.state }}</div>
            </div>
          </el-col>
        </el-row>

        <b-pagination
          :page="page"
          @change="fetchList"
        ></b-pagination>
      </div>
    </b-section-scroll>
  </div>
</template>

<script>
import { getBacklogPage } from '@/api/backlog'
import { stateColorMap } from '@/config/dict'

export default {
  data() {
    return {
      activeName: 'dept',
      state: '进行中',
      loading: false,
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      list: [],
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  watch: {
    '$route.query.status': {
      handler(status) {
        this.state = status || '进行中'
      },
      immediate: true,
    },
    '$route.query.active': {
      handler(active) {
        this.activeName = active || 'dept'
      },
      immediate: true,
    },
    'activeName': {
      handler() {
        this.onRefresh()
      },
      immediate: true,
    },
    'state': {
      handler() {
        this.onRefresh()
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 同时改变 active 和 state 的时候重复请求
     */
    fetchList() {
      this.loading = true
      const id = this.activeName === 'user' ? this.userId : this.deptId
      const params = {
        state: this.state,
        current_page: this.page.current,
        page: this.page.current,
        current_count: this.page.size,
      }
      getBacklogPage(id, params, this.activeName)
        .then(res => {
          this.list = res.data
          this.page.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onNav(row) {
      this.$router.push({
        path: '/task/info',
        query: { backlogId: row.id, id: row.agenda_id, type: 'backlog' },
      })
    },
    handlerColor(state) {
      return stateColorMap[state] ?? '#17c167'
    },
    handlerStatus(row) {
      if (
        row.state === '进行中' &&
        this.$tools.dayjs().isAfter(this.$tools.dayjs(row.executive_end_data))
      ) {
        return true
      }
      return false
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f1;
}

.list-item {
  position: relative;
  padding: 10px;
  font-size: 12px;
  line-height: 20px;
  color: #747e8f;
  cursor: pointer;
  background: rgba($color: #dfe3e8, $alpha: 0.26);
  border: 1px solid rgba($color: #dfe3e8, $alpha: 0.26);
  border-radius: 8px;

  &.warning {
    border-color: #f40;
  }

  &:hover {
    box-shadow: 1px 1px 10px 0 #ccc;
  }

  .title {
    width: 100%;
    height: 48px;
    padding-right: 70px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    color: #2b2d42;
  }

  .state {
    position: absolute;
    top: 20px;
    right: 0;
    width: 70px;
    height: 28px;
    padding-right: 5px;
    padding-left: 10px;
    font-size: 12px;
    line-height: 28px;
    color: #fff;
    text-align: right;
    background-color: #17c167;
    border-radius: 15px 0 0 15px;
  }
}
</style>
